<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="名称" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name ? scope.row.name : scope.row.nickname }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="电话" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.l_mobile }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="内容" align="center">
				  <template slot-scope="scope">
					<span v-for="(item,index) in scope.row.params">{{item.title || ''}},值:{{item.value || ''}};</span>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" max-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		<div class="w-100 h-auto flex flex-column">
        
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'wj_list',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				id:'',
				keyword: '',
			},
			id:'',
		}
	},
	mounted() {
		if(this.$route.query.id){
			 this.queryForm.id = this.$route.query.id
			 this.getList()
		}
	},
	methods:{
		getList() {
		  this.loading = true
		  this.axios.get(`/manage/${this.preUrl}/list`, {
		    params: this.queryForm
		  }).then(res => {
		    this.total = parseInt(res.total)
		    this.list = res.data
			res.data.forEach((v,k)=>{
				this.list[k].params = JSON.parse(v.extra.params)
			})
			this.extra = JSON.parse(res.data.extra)
		    this.loading = false
		  }).catch(err => {
		    this.loading = false
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
}
</script>

<style>
</style>